import React from "react";
import { withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import {
  upperCaseRegex,
  lowerCaseRegex,
  oneNumberRegex,
  oneSymbolRegex,
} from "../../../../../utils/validators";
import { PasswordMeter } from "password-meter";
// const passwordMeter = require("password-meter");

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 8,
    borderRadius: 4,
    marginTop: 10,
    marginBottom: -10,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 4,
    backgroundColor: ({ value }) => {
      if (value <= 40) return theme.palette.error.main;
      if (value > 40 && value <= 80) return theme.palette.warning.main;
      if (value > 80) return theme.palette.primary.main;
    },
  },
}))(LinearProgress);

const calculatePasswordStrength = (val) => {
  // let strength = 0;

  // if (val.length >= 8) strength = strength + 20;

  // if (val?.match(upperCaseRegex)) strength = strength + 20;

  // if (val?.match(lowerCaseRegex)) strength = strength + 20;

  // if (val?.match(oneNumberRegex)) strength = strength + 20;

  // if (val?.match(oneSymbolRegex)) strength = strength + 20;

  let newVal =
    new PasswordMeter(
      {},
      {
        "100": "Low", // 001 <= x <  100
        _: "High", //        x >= 100
      }
    ).getResults([val])[0]?.percent || 0;

  if (newVal > 75) {
    if (val.length < 12) newVal = newVal - 10;
  }

  if (newVal > 75) {
    if (val.length < 8) newVal = newVal - 15;

    if (!val?.match(upperCaseRegex)) newVal = newVal - 15;

    if (!val?.match(lowerCaseRegex)) newVal = newVal - 15;

    if (!val?.match(oneNumberRegex)) newVal = newVal - 15;

    if (!val?.match(oneSymbolRegex)) newVal = newVal - 15;
  }

  return newVal;
  // return strength;
};

export const PasswordProgressBar = ({ value }) => {
  return (
    <BorderLinearProgress
      variant="determinate"
      value={calculatePasswordStrength(value)}
    />
  );
};

export const generatePasswordLabel = (val, label) => {
  let strength = calculatePasswordStrength(val);
  if (strength <= 40) return label.weakPassword;
  if (strength > 40 && strength < 90) return label.goodPassword;
  return "";
};

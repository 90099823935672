import React, { useEffect } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
  Typography,
  Tooltip,
} from "@material-ui/core";
import { useStrongPasswordCompStyles } from "../../../styles/StrongPasswordCompStyles";
import { getLabels } from "../../../labels";
import classNames from "classnames";
import Check from "@material-ui/icons/CheckCircleOutline";
import UnCheck from "@material-ui/icons/RadioButtonUnchecked";
import { PasswordProgressBar } from "./PasswordProgressBar";
import {
  upperCaseRegex,
  lowerCaseRegex,
  oneNumberRegex,
  oneSymbolRegex,
} from "../../../../../utils/validators";
import { useState } from "react";

const pwValidation = (id, val) => {
  switch (id) {
    case "char8":
      return val.length >= 8 ? true : false;
    case "upperCase":
      return val?.match(upperCaseRegex) ? true : false;
    case "lowerCase":
      return val?.match(lowerCaseRegex) ? true : false;
    case "oneNumber":
      return val?.match(oneNumberRegex) ? true : false;
    case "oneSymbol":
      return val?.match(oneSymbolRegex) ? true : false;
    default:
      return false;
  }
};

const PwRuleListItem = ({ id, label, value }) => {
  const classes = useStrongPasswordCompStyles();

  return (
    <ListItem key={id} dense>
      <ListItemIcon>
        <Checkbox
          edge="start"
          size="small"
          htmlFor={id}
          disabled
          disableRipple
          tabIndex={-1}
          checked={(value && pwValidation(id, value)) || false}
          color="primary"
          icon={<UnCheck />}
          checkedIcon={<Check color="primary" />}
          classes={{ root: classes.checkBox }}
        />
      </ListItemIcon>
      <ListItemText id={id} secondary={label} />
    </ListItem>
  );
};

export const PasswordPolicy = ({ value = "", position = "topRight" }) => {
  const classes = useStrongPasswordCompStyles();
  const labels = getLabels("en");

  const passwordRule = [
    { label: labels.char8, id: "char8" },
    { label: labels.upperCase, id: "upperCase" },
    { label: labels.lowerCase, id: "lowerCase" },
    { label: labels.oneNumber, id: "oneNumber" },
    { label: labels.oneSymbol, id: "oneSymbol" },
  ];
  return (
    <>
      <Typography variant="subtitle2" color="textSecondary">
        {labels.passwordPolicy}
      </Typography>
      <List className={classes.listGroup} dense>
        {passwordRule.map((i) => {
          return (
            <PwRuleListItem
              key={i.id}
              id={i.id}
              label={i.label}
              value={value}
            />
          );
        })}
      </List>
    </>
  );
};

export const StrongPasswordComp = ({
  children,
  value = "",
  // position = "topRight",
  tooltip = false,
  placement,
  passwordProgressBar = false,
  pwRef,
}) => {
  const classes = useStrongPasswordCompStyles();
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    if (pwRef) {
      const { current } = pwRef;

      const handleFocus = () => {
        setIsOpen(true);
      };
      const handleBlur = () => {
        setIsOpen(false);
      };

      current.addEventListener("focus", handleFocus);
      current.addEventListener("blur", handleBlur);

      return () => {
        current.removeEventListener("focus", handleFocus);
        current.removeEventListener("blur", handleBlur);
      };
    }
  });
  return (
    <>
      {tooltip ? (
        <Tooltip
          title={<PasswordPolicy value={value} />}
          open={pwRef ? isOpen : undefined}
          // open={true}
          arrow
          placement={placement || "bottom"}
          classes={{
            tooltip: classNames(
              classes.ruleBox,
              placement && classes.tooltip,
              pwRef && classes.overwriteTooltip
            ),
          }}
        >
          {children}
        </Tooltip>
      ) : (
        children
      )}
      {passwordProgressBar && <PasswordProgressBar value={value} />}
    </>
  );
};

//   return (
//     <Tooltip
//       title={<PasswordPolicy value={value} />}

//       arrow
//       placement={placement || "bottom"}
//       classes={{
//         tooltip: classNames(classes.ruleBox, placement && classes.tooltip),
//       }}
//     >
//       {children}
//     </Tooltip>
//   );
// } else if (tooltip || passwordProgressBar)

// else
//   return (
//     <Box position="relative" className={classes.wrapper}>
//       {children}
//       <Box
//         // position="fixed"
//         position="absolute"
//         className={classNames(classes.ruleBox, classes[position], "ruleBox")}
//       >
//         <PasswordPolicy value={value} position={position} />
//       </Box>
//     </Box>
//   );

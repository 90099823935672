import { makeStyles } from "@material-ui/core/styles";
import { TooltipProps } from "@material-ui/core/Tooltip";
export const StrongPasswordCompStyles = (theme) => ({
  wrapper: {
    display: "flex",
    "&:focus-within .ruleBox, & .MuiInputAdornment-root:hover .ruleBox": {
      display: "unset",
    },
    "& .ruleBox": {
      display: "none",
    },
  },
  tooltip: {
    top: "-16px",
    left: "6px",
    "& span": {
      color: "rgba(0, 0, 0, 0.23)!important",
    },
  },
  overwriteTooltip: {
    top: 0,
    left: "-8px",
  },
  ruleBox: {
    border: "1px solid rgba(0, 0, 0, 0.23)",
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    background: "#fdfdfd",
    paddingRight: theme.spacing(7),
    "& ul li": {
      padding: 0,

      "& div": {
        minWidth: "unset",
        marginTop: 0,
        marginBottom: 0,

        "& span": {
          paddingTop: "2px",
          paddingBottom: "2px",
        },

        "& p": {
          fontSize: "12px",
        },
      },
    },
  },
  topRight: {
    top: 0,
    right: "-70%",
    // paddingRight: theme.spacing(7),
    // transform: "translate(150%,1%)",
  },
  topLeft: {
    top: 0,
    left: "-65%",
    // paddingRight: theme.spacing(7),
    // transform: "translate(-115%,1%)",
  },
  checkBox: {
    minWidth: "unset",
  },
});

export const useStrongPasswordCompStyles = makeStyles(StrongPasswordCompStyles);
// "&::after": {
//   content: "1",
//   position: "absolute",
//   top: "100%",
//   left: "50%",
//   marginLeft: "-5px",
//   borderWidth: "5px",
//   borderStyle: "solid",
//   borderColor: "black transparent transparent transparent",
// },
